import { ResizeObserver } from '@juggle/resize-observer';

import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/800.css';

import './src/styles.css';

// eslint-disable-next-line import/prefer-default-export
export const onClientEntry = () => {
  if (!('ResizeObserver' in window)) {
    window.ResizeObserver = ResizeObserver;
  }
  if (!('IntersectionObserver' in window)) {
    import('intersection-observer');
  }
  if (!('URLSearchParams' in window)) {
    import('url-search-params-polyfill');
  }
};
